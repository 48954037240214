<template>
  <div>
    <section-banner
      v-for="(section, index) in sections"
      :key="index"
      :model="section"
    ></section-banner>
  </div>
</template>

<script>
import SectionBanner from "@/components/Frontend/SectionBanner";

export default {
  name: "EstatePlanning",
  components: {
    SectionBanner,
  },
  data() {
    return {
      sections: [
        {
          Name: "What we do",
          Title: "Estate Planning",
          Subtitle: "Getting your affairs in order",
          Description:
            "Organise your affairs so that those you may leave behind are well-looked after.",
          img: require("@/assets/img/Helanie2.jpg"),
        },
        {
          TextColumn: true,
          TextColumn1: `<p>We can help you make sure your wishes are carried out to the letter, and that those who matter most to you are cared for.<br></p>
        <p>Let us help you craft rock-solid:<br></p>
        <ul>
          <li>Antenuptial Contracts</li>
          <li>Trusts</li>
          <li>Wills</li>
        </ul>`,
          TextColumn2: false,
        },
        {
          Profiles: true,
          Name: "Talk to Us",
          Bios: [
            {
              Title: "Natalie Lubbe",
              Subtitle: "Director",
              Linkedin: "linkedin.com/in/natalielubbe",
              Email: "natalie@NLAteam.com",
              Tel: "+27 11 704 1563",
              img: require("@/assets/img/Natalie-bio.jpg"),
              left: true,
            },
            {
              Title: "Helani van der Westhuizen",
              Subtitle: "Director",
              Linkedin: "linkedin.com/in/helani-van-der-westhuizen",
              Email: "helani@NLAteam.com",
              Tel: "+27 11 704 1563",
              img: require("@/assets/img/Helanie-bio.jpg"),
              left: false,
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
